.izquierda {
    text-align: left;
}

.derecha {
    text-align: right;
}

.centro {
    text-align: center;
}

.rellenoSuperior {
    height: 30vh;
}

.rellenoCentral {
    height: 20vh;
}

.rellenoInferior {
    height: 30vh;
}